import React, { useState } from 'react';
import './Enterprise.css'; // Link to Enterprise specific CSS
import HeroImage from '../Hero_1.png'; // Reuse assets
import FeatureGif1 from '../Feature_1.gif';
import FeatureGif2 from '../Feature_2.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { // Import brand icons
    faWindows,
    faApple,
    faAndroid,
    faChrome,
} from '@fortawesome/free-brands-svg-icons';
import { // Import solid icons
    faUsers, // Collaboration
    faLock, // Security
    faServer, // Scalability
    faHeadset, // Support
    faQuestionCircle,
    faCheckCircle,
    faCogs, // Customization / Integration
    faCode, // API Access
    faStar, // Priority Features
    faChalkboardTeacher, // Training
    faCloudUploadAlt,
    faDesktop
} from '@fortawesome/free-solid-svg-icons';

function Enterprise() {
    const [faqActiveIndex, setFaqActiveIndex] = useState(null);

    // Enterprise-focused FAQ data
    const faqData = [
        { question: "How does Neocu ensure data security for enterprise clients?", answer: "Neocu employs industry-standard security practices, including data encryption, access controls, and regular audits. We offer options for enhanced security configurations based on enterprise needs." },
        { question: "Can Neocu integrate with our existing tools and workflows?", answer: "Yes, Neocu is designed for integration. We offer APIs and support for connecting with your existing asset management systems, project tools, and rendering pipelines." },
        { question: "What kind of support options are available for enterprise plans?", answer: "Enterprise plans include dedicated account managers, priority technical support, custom onboarding, and optional training programs tailored to your team." },
        { question: "Is Neocu scalable for large teams and complex projects?", answer: "Absolutely. Neocu's cloud infrastructure is built to scale, and our collaboration features support large teams working concurrently on complex 3D projects." },
        { question: "Can we request custom features or AI model training?", answer: "We work closely with our enterprise partners. Custom feature development and tailored AI model training can be discussed as part of an enterprise agreement." },
        { question: "What are the pricing models for enterprise?", answer: "Enterprise pricing is customized based on team size, usage requirements, and specific needs like support and integration. Please contact our sales team for a personalized quote." }
    ];

    // Enterprise-focused card data
    const cardData1 = [
        { icon: faUsers, title: "Team Collaboration", description: "Enable seamless collaboration across your organization with shared projects and real-time updates." },
        { icon: faLock, title: "Enhanced Security", description: "Benefit from enterprise-grade security features, compliance standards, and data privacy controls." },
        { icon: faServer, title: "Scalable Infrastructure", description: "Reliable and scalable cloud infrastructure that grows with your team and project demands." },
        { icon: faHeadset, title: "Dedicated Support", description: "Access priority support channels, dedicated account management, and custom onboarding." }
    ];

    const cardData2 = [
        { icon: faCogs, title: "Custom Integration", description: "Integrate Neocu into your pipeline with API access and custom development support." },
        { icon: faCheckCircle, title: "Streamlined Workflows", description: "Optimize your 3D production pipeline with AI-driven automation tailored for enterprise scale." },
        { icon: faChalkboardTeacher, title: "Team Training", description: "Customized training programs to ensure your team maximizes productivity with Neocu." },
        { icon: faCode, title: "API & SDK Access", description: "Leverage Neocu's capabilities programmatically for deeper integration and automation." },
        { icon: faStar, title: "Priority Access", description: "Influence the product roadmap and get early access to new features relevant to your business." },
        { icon: faDesktop, title: "Flexible Deployment", description: "Options for cloud or hybrid deployment models to fit your organization's infrastructure." }
    ];


    const toggleFAQ = (index) => {
        setFaqActiveIndex(faqActiveIndex === index ? null : index);
    };

    return (
        <div className="enterprise-container"> {/* Use specific class */}

            {/* Hero Section - Enterprise Focus */}
            <section className="hero-section">
                <div className="hero-content-area">
                    <h1 className="hero-title">Neocu for Enterprise</h1>
                    <p className="hero-description">Scale your 3D content creation with AI-powered tools built for teams, security, and performance.</p>
                    <div className="hero-buttons">
                        <a
                            href="/contact-sales" // Example link - update as needed
                            className="hero-download-button windows-button" // Reusing style, consider renaming class if needed
                            // target="_blank" // Remove if it's an internal link
                            // rel="noopener noreferrer"
                        >
                            CONTACT SALES
                        </a>
                        <a
                            href="/features" // Link to features page
                            className="hero-download-button all-downloads-button"
                        >
                            EXPLORE FEATURES
                        </a>
                    </div>
                </div>
                <div className="hero-image-container">
                    <img src={HeroImage} alt="Enterprise Hero" className="hero-image" />
                </div>
            </section>

            {/* Feature Section 1 - Enterprise Focus */}
            <section className="feature-section feature-section-tab">
                <div className="feature-text-area">
                    <h2 className="feature-title">Seamless Integration</h2>
                    <p className="feature-description">Integrate Neocu's AI capabilities into your existing workflows and pipelines effortlessly.</p>
                </div>
                <div className="feature-image-background">
                    <div className="feature-gif-container">
                        <img src={FeatureGif1} alt="Integration GIF" className="feature-gif" />
                    </div>
                </div>
            </section>

            {/* Feature Section 2 - Enterprise Focus */}
            <section className="feature-section feature-section-codebase">
                <div className="feature-text-area">
                    <h2 className="feature-title">Secure & Scalable</h2>
                    <p className="feature-description">Enterprise-grade security, dedicated support, and infrastructure that grows with your needs.</p>
                </div>
                <div className="feature-image-background">
                    <div className="feature-gif-container">
                        <img src={FeatureGif2} alt="Security GIF" className="feature-gif" />
                    </div>
                </div>
            </section>

            {/* 4-Card Section - Enterprise Benefits */}
            <section className="card-section card-section-4">
                <h2 className="card-section-title">Core Enterprise Benefits</h2>
                <div className="card-grid card-grid-4">
                    {cardData1.map((card, index) => (
                        <div key={index} className="card card-4">
                            <FontAwesomeIcon icon={card.icon} className="card-icon" />
                            <h3 className="card-title">{card.title}</h3>
                            <p className="card-description">{card.description}</p>
                        </div>
                    ))}
                </div>
            </section>

            {/* FAQ Section - Enterprise Focus */}
            <section className="faq-section">
                <h2 className="faq-section-title">Enterprise FAQs</h2>
                <div className="faq-list">
                    {faqData.map((faq, index) => (
                        <div key={index} className="faq-item">
                            <div className="faq-question" onClick={() => toggleFAQ(index)}>
                                {faq.question}
                                <span className={`dropdown-arrow ${faqActiveIndex === index ? 'active' : ''}`}>▼</span>
                            </div>
                            {faqActiveIndex === index && (
                                <div className="faq-answer">
                                    {faq.answer}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </section>

            {/* 6-Card Section - More Enterprise Advantages */}
            <section className="card-section card-section-6">
                <h2 className="card-section-title">Advanced Advantages</h2>
                <div className="card-grid card-grid-6">
                    {cardData2.map((card, index) => (
                        <div key={index} className="card card-6">
                            <FontAwesomeIcon icon={card.icon} className="card-icon" />
                            <h3 className="card-title">{card.title}</h3>
                            <p className="card-description">{card.description}</p>
                        </div>
                    ))}
                </div>
            </section>

        </div>
    );
}

export default Enterprise;