// src/blogs/Blogs.js
import React from 'react';
import { Link } from 'react-router-dom';
import './article.css'; // Use article.css for some base styling if needed, or create blogs.css

const blogPosts = [
  {
    slug: 'blender-shortcuts',
    title: 'Mastering Blender: Essential Keyboard Shortcuts',
    excerpt: 'Unlock speed and precision in your 3D modeling with must-know Blender shortcuts for navigation, editing, and more.',
    image: '/images/blog/blender-shortcuts-thumb.jpg', // Placeholder path - adjust as needed
  },
 
  {
    slug: 'blender-grease-pencil',
    title: 'Unleashing Creativity: Blender\'s Grease Pencil Tips',
    excerpt: 'Explore the powerful 2D animation and storyboarding capabilities within Blender using the Grease Pencil toolset.',
    image: '/images/blog/blender-gp-thumb.jpg', // Placeholder path
  },
  {
    slug: 'blender-extrude',
    title: 'The Power of Extrude in Blender 3D Modeling',
    excerpt: 'Master one of the most fundamental modeling tools in Blender. Learn various extrude techniques and applications.',
    image: '/images/blog/blender-extrude-thumb.jpg', // Placeholder path
  },
  {
    slug: 'blender-uv-mapping',
    title: 'Demystifying UV Mapping in Blender',
    excerpt: 'Learn the essentials of UV unwrapping and mapping textures onto your 3D models accurately in Blender.',
    image: '/images/blog/blender-uv-thumb.jpg', // Placeholder path
  },
    {
    slug: 'blender-realistic-lighting',
    title: 'Achieving Realistic Lighting in Blender Cycles & Eevee',
    excerpt: 'Elevate your renders with techniques for creating believable and atmospheric lighting setups in Blender.',
    image: '/images/blog/blender-lighting-thumb.jpg', // Placeholder path
  },
  // Add more blog posts here
];

function Blogs() {
  return (
    <div className="blogs-page-container">
      <h1 className="blogs-page-title">Our Blender 3D Insights</h1>
      <p className="blogs-page-subtitle">Tips, tricks, and tutorials to enhance your Blender workflow.</p>

      <div className="blog-list">
        {blogPosts.map((post) => (
          <div key={post.slug} className="blog-card">
             {/* Basic image placeholder - replace with actual <img> if desired */}
             <div className="blog-card-image-placeholder"></div>
             {/* <img src={post.image} alt={`${post.title} thumbnail`} className="blog-card-image" /> */}
            <div className="blog-card-content">
              <h2 className="blog-card-title">{post.title}</h2>
              <p className="blog-card-excerpt">{post.excerpt}</p>
              <Link to={`/blogs/${post.slug}`} className="blog-card-read-more">
                Read Article →
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Blogs;