// src/App.js
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css'; // Global App CSS
import Header from './components/Header';
import Home from './components/Home';
import Pricing from './components/Pricing';
import Features from './components/Features';
import Enterprise from './components/Enterprise';
import Careers from './components/Careers';
import Blogs from './blogs/Blogs';

// --- Lazy load components ---
// Blog components
const BlenderShortcuts = lazy(() => import('./blogs/BlenderShortcuts'));
const BlenderGreasePencil = lazy(() => import('./blogs/BlenderGreasePencil'));
const BlenderExtrude = lazy(() => import('./blogs/BlenderExtrude'));
const BlenderUVMapping = lazy(() => import('./blogs/BlenderUVMapping'));
const BlenderLighting = lazy(() => import('./blogs/BlenderLighting'));

// Game component (also lazy load for consistency)
const Game = lazy(() => import('./components/Game'));

// --- Loading Fallback Component ---
// You can reuse this or create a specific one for game route if needed
const LoadingFallback = ({ message = "Loading..." }) => ( // Added optional message prop
  <div style={{ textAlign: 'center', padding: '50px', fontSize: '1.2em', color: '#ff7f00', minHeight: 'calc(100vh - 120px)', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'black' }}>
    {message}
  </div>
);


function App() {
  return (
    <Router>
      <div className="App">
        <Header /> {/* Ensure Header is present */}

        <Routes>
          {/* Standard Pages */}
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/features" element={<Features />} />
          <Route path="/enterprise" element={<Enterprise />} />
          <Route path="/careers" element={<Careers />} />

          {/* Blog Listing */}
          <Route path="/blogs" element={<Blogs />} />

          {/* Individual Blog Posts (Lazy Loaded) */}
          <Route
            path="/blogs/blender-shortcuts"
            element={
              <Suspense fallback={<LoadingFallback message="Loading Article..." />}>
                <BlenderShortcuts />
              </Suspense>
            }
          />
          <Route
            path="/blogs/blender-grease-pencil"
            element={
              <Suspense fallback={<LoadingFallback message="Loading Article..." />}>
                <BlenderGreasePencil />
              </Suspense>
            }
          />
          <Route
            path="/blogs/blender-extrude"
            element={
              <Suspense fallback={<LoadingFallback message="Loading Article..." />}>
                <BlenderExtrude />
              </Suspense>
            }
          />
          <Route
            path="/blogs/blender-uv-mapping"
            element={
              <Suspense fallback={<LoadingFallback message="Loading Article..." />}>
                <BlenderUVMapping />
              </Suspense>
            }
          />
           <Route
            path="/blogs/blender-realistic-lighting"
            element={
              <Suspense fallback={<LoadingFallback message="Loading Article..." />}>
                <BlenderLighting />
              </Suspense>
            }
          />

          {/* NEW Game Route (Lazy Loaded) */}
          <Route
            path="/game/:id" // Use :id for the parameter
            element={
              <Suspense fallback={<LoadingFallback message="Loading Game..." />}>
                <Game />
              </Suspense>
            }
          />

          {/* Optional: Add a 404 Not Found Route */}
          {/* <Route path="*" element={<NotFound />} /> */}

        </Routes>

        {/* Optional: Footer Component */}
        {/* <Footer /> */}
      </div>
    </Router>
  );
}

export default App;