import React, { useState } from 'react';
import './Pricing.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

function Pricing() {
    const [monthlyPricing, setMonthlyPricing] = useState(true);
    const [faqActiveIndex, setFaqActiveIndex] = useState(null);

    const togglePricingPeriod = () => {
        setMonthlyPricing(!monthlyPricing);
    };

    const faqData = [
        { question: "Can I cancel my subscription?", answer: "Yes, you can cancel your subscription at any time. Your access will continue until the end of your current billing cycle." },
        { question: "Is there a free trial?", answer: "While the Hobby plan is free, upgrading to Pro or Business might offer trial periods or specific introductory offers. Please check the specific plan details when signing up." },
        { question: "What payment methods do you accept?", answer: "We accept major credit cards including Visa, MasterCard, American Express, and Discover, as well as PayPal." },
        { question: "Can I change plans later?", answer: "Yes, you can upgrade or downgrade your plan at any time. Changes will be effective from your next billing cycle." },
        { question: "Do you offer custom plans for enterprises?", answer: "Yes, for enterprise clients, we offer custom plans with tailored features and support. Please contact our sales team for more information." },
        { question: "How secure is my data?", answer: "We take data security very seriously. We employ industry-standard security measures to protect your data and ensure privacy." }
    ];

    // Define the master feature list
    const masterFeatures = [
        { text: "Intelligent Canvas", hobbyIncluded: true },
        { text: "Project Management", hobbyIncluded: true },
        { text: "Image To 3D", hobbyIncluded: false },
        { text: "Video To 3D", hobbyIncluded: false },
        { text: "PDF To 3D", hobbyIncluded: false },
        { text: "Neocu Render", hobbyIncluded: false },
        { text: "Unlimited Exports", hobbyIncluded: true },
        { text: "Unlimited Actions", hobbyIncluded: false },
        { text: "Unlimited AI 3D Generation", hobbyIncluded: false },
        { text: "Unlimited Texture Generations", hobbyIncluded: false },
        { text: "Usage Rollover", hobbyIncluded: false },
        { text: "1GB Storage", hobbyIncluded: false },
    ];

    // Helper function to generate features for a plan
    const getPlanFeatures = (planName) => {
        return masterFeatures.map(feature => ({
            text: feature.text,
            included: planName === 'Hobby' ? feature.hobbyIncluded : true // All included for Pro/Business
        }));
    };

     // Original Business features to add ON TOP of Pro features for the Business plan
     const businessSpecificFeatures = [
        { text: "Enforce privacy mode org-wide", included: true },
        { text: "Centralized team billing", included: true },
        { text: "Admin dashboard with usage stats", included: true },
        { text: "SAML/OIDC SSO", included: true },
    ];

    // Editor Link Constant
    const editorLink = "https://editor.neocu.com";


    const plans = {
        monthly: [
            {
                name: "Hobby",
                price: "Free",
                details: "Includes basic features:",
                features: getPlanFeatures('Hobby'),
                buttonText: "DOWNLOAD",
                buttonStyle: "download"
                // No buttonLink for Hobby Download
            },
            {
                name: "Pro",
                price: "$30",
                period: "/month",
                details: "Everything in Hobby, plus:",
                features: getPlanFeatures('Pro'),
                buttonText: "GET STARTED",
                buttonStyle: "get-started",
                buttonLink: editorLink // Added link
            },
            {
                name: "Business",
                price: "$99",
                period: "/user/month",
                details: "Everything in Pro, plus:",
                features: [
                    ...getPlanFeatures('Business'), // Include all master features
                    ...businessSpecificFeatures     // Add business-specific ones
                ],
                buttonText: "GET STARTED",
                buttonStyle: "get-started",
                buttonLink: editorLink // Added link
            }
        ],
        yearly: [
            {
                name: "Hobby",
                price: "Free",
                details: "Includes basic features:",
                features: getPlanFeatures('Hobby'),
                buttonText: "DOWNLOAD",
                buttonStyle: "download"
                // No buttonLink for Hobby Download
            },
            {
                name: "Pro",
                price: "$21", // Updated Price (30 * 0.7 = 21)
                period: "/month",
                details: "Everything in Hobby, plus:",
                features: getPlanFeatures('Pro'),
                buttonText: "GET STARTED",
                buttonStyle: "get-started",
                buttonLink: editorLink // Added link
            },
            {
                name: "Business",
                price: "$69", // Updated Price (99 * 0.7 = 69.3, rounded to 69)
                period: "/user/month",
                details: "Everything in Pro, plus:",
                features: [
                    ...getPlanFeatures('Business'), // Include all master features
                    ...businessSpecificFeatures     // Add business-specific ones
                ],
                buttonText: "GET STARTED",
                buttonStyle: "get-started",
                buttonLink: editorLink // Added link
            }
        ]
    };

    const currentPlans = monthlyPricing ? plans.monthly : plans.yearly;

    const toggleFAQ = (index) => {
        setFaqActiveIndex(faqActiveIndex === index ? null : index);
    };

    // Helper to render button or link
    const renderPlanButton = (plan) => {
        const buttonContent = (
            <button className={`plan-button ${plan.buttonStyle}-button`}>
                {plan.buttonText}
            </button>
        );

        if (plan.buttonLink) {
            return (
                <a href={plan.buttonLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', display: 'block', marginTop: 'auto' }}>
                    {buttonContent}
                </a>
            );
        } else {
            // For buttons without a link (like DOWNLOAD), wrap in a div to apply margin-top: auto correctly
            return (
                 <div style={{ marginTop: 'auto', width: '100%', display: 'flex', justifyContent: 'center' }}>
                     {buttonContent}
                 </div>
            );
        }
    };


    return (
        <div className="pricing-container">
            <header className="pricing-header">
                <h1>Pricing</h1>
                <p>Choose the plan that works for you</p>
            </header>
            <div className="pricing-tabs">
                <button
                    className={`pricing-tab-button ${monthlyPricing ? 'active' : ''}`}
                    onClick={togglePricingPeriod}
                    disabled={monthlyPricing} // Disable if already active
                >
                    MONTHLY
                </button>
                <button
                    className={`pricing-tab-button ${!monthlyPricing ? 'active' : ''}`}
                    onClick={togglePricingPeriod}
                    disabled={!monthlyPricing} // Disable if already active
                >
                    YEARLY <span className="save-badge">(SAVE 30%)</span> {/* Updated Badge Text */}
                </button>
            </div>
            <main className="pricing-main">
                {currentPlans.map((plan, index) => (
                    <section key={index} className={`pricing-plan ${plan.name.toLowerCase()}-plan`}>
                        <h2 className="plan-name">{plan.name}</h2>
                        <div className="price-area">
                            <p className="price">
                                {plan.price}
                                {plan.price !== "Free" && <span className="period">{plan.period}</span>}
                            </p>
                        </div>
                        <p className="plan-details">{plan.details}</p>
                        <ul className="features-list">
                            {plan.features.map((feature, featureIndex) => (
                                <li key={featureIndex}>
                                    {feature.included ? (
                                        <FontAwesomeIcon icon={faCheck} className="check-icon" />
                                    ) : (
                                        <span className="cross-icon">✕</span>
                                    )}
                                    {feature.text}
                                </li>
                            ))}
                        </ul>
                        {/* Use the helper function to render the button/link */}
                        {renderPlanButton(plan)}
                    </section>
                ))}
            </main>

            {/* FAQ Section */}
            <section className="faq-section">
                <h2 className="faq-section-title">FAQ</h2>
                <div className="faq-list">
                    {faqData.map((faq, index) => (
                        <div key={index} className="faq-item">
                            <div className="faq-question" onClick={() => toggleFAQ(index)}>
                                {faq.question}
                                <span className={`dropdown-arrow ${faqActiveIndex === index ? 'active' : ''}`}>▼</span>
                            </div>
                            {faqActiveIndex === index && (
                                <div className="faq-answer">
                                    {faq.answer}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
}

export default Pricing;