import React, { useState } from 'react';
import './Home.css';
import logo from '../logo.svg';
import HeroImage from '../Hero_1.png';
import FeatureGif1 from '../Feature_1.gif';
import FeatureGif2 from '../Feature_2.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { // Import brand icons separately
    faWindows,
    faApple,
    faAndroid,
    faChrome,
} from '@fortawesome/free-brands-svg-icons';
import { // Import solid icons separately
    faMagic,
    faCube,
    faLightbulb,
    faRocket,
    faQuestionCircle,
    faCheckCircle,
    faTools,
    faUsers,
    faLock,
    faClock,
    faCloudUploadAlt,
    faDesktop
} from '@fortawesome/free-solid-svg-icons';

function Home() {
    const [faqActiveIndex, setFaqActiveIndex] = useState(null);

    const faqData = [
        { question: "What is Neocu?", answer: "Neocu is an AI-powered 3D editor designed to make 3D content creation extraordinarily productive and accessible to everyone." },
        { question: "What platforms does Neocu support?", answer: "Neocu is currently available for Windows, macOS, Android, and Chromebook, ensuring you can work across your devices." },
        { question: "Does Neocu use AI?", answer: "Yes, Neocu leverages advanced AI to automate complex 3D editing tasks, predict user needs, and enhance creative workflows, including features like Image to 3D and Video to 3D." }, // Added mention of features
        { question: "Is Neocu suitable for beginners?", answer: "Absolutely! Neocu's intuitive interface is designed for both beginners and professionals, making 3D editing easy to learn and use." },
        { question: "What kind of 3D content can I create with Neocu?", answer: "With Neocu, you can create a wide range of 3D content, including models, scenes, animations, and interactive 3D experiences for various applications. You can also generate 3D models from images and videos." }, // Added mention of generation
        { question: "How is Neocu priced?", answer: "Please visit our Pricing page for detailed information on our subscription plans and options tailored to different user needs." }
    ];

    const cardData1 = [
        { icon: faMagic, title: "AI-Powered", description: "Smart tools that understand your intent and streamline creation." },
        { icon: faCube, title: "3D Made Easy", description: "Intuitive interface for effortless 3D modeling and scene building." },
        { icon: faLightbulb, title: "Boost Productivity", description: "Automate tasks, predict edits, and work faster than ever before." },
        { icon: faRocket, title: "Cross-Platform", description: "Use Neocu on Windows, Mac, Android, and Chromebook seamlessly." }
    ];

    const cardData2 = [
        { icon: faQuestionCircle, title: "Contextual Help", description: "AI-driven assistance always available to guide you." },
        { icon: faCheckCircle, title: "One-Click Actions", description: "Execute complex operations with a single click using AI." },
        { icon: faTools, title: "Versatile Tools", description: "Comprehensive suite of tools for every 3D editing need." },
        { icon: faUsers, title: "Collaborative Ready", description: "Features designed for team collaboration on 3D projects." },
        { icon: faLock, title: "Secure & Private", description: "Your data and creations are kept secure and private." },
        { icon: faClock, title: "Time-Saving", description: "Drastically reduce time spent on 3D content creation." }
    ];


    const toggleFAQ = (index) => {
        setFaqActiveIndex(faqActiveIndex === index ? null : index);
    };


    return (
        <div className="home-container">


            {/* Hero Section */}
            <section className="hero-section">
                <div className="hero-content-area">
                    <h1 className="hero-title">The AI 3D Editor</h1>
                    <p className="hero-description">Build stunning 3D experiences faster than ever. Neocu is productivity, redefined.</p>
                    <div className="hero-buttons">
                        <a
                            href="https://editor.neocu.com"
                            className="hero-download-button windows-button"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FontAwesomeIcon icon={faWindows} /> DOWNLOAD FOR WINDOWS
                        </a>
                        <a
                            href="https://editor.neocu.com"
                            className="hero-download-button all-downloads-button"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            TRY FOR FREE
                        </a>
                    </div>
                </div>
                <div className="hero-image-container">
                    <img src={HeroImage} alt="Neocu AI 3D Editor Interface" className="hero-image" />
                </div>
            </section>

            {/* Feature Section 1 */}
            {/* --- UPDATED SECTION 1 START --- */}
            <section className="feature-section feature-section-tab">
                <div className="feature-text-area">
                    <h2 className="feature-title">Intelligent 3D Editing</h2>
                    <p className="feature-description">Neocu uses AI to understand your context, suggesting edits and automating complex 3D tasks, boosting your productivity.</p>
                </div>
                <div className="feature-image-background">
                    <div className="feature-gif-container">
                        <img src={FeatureGif1} alt="Neocu AI Assisted 3D Editing" className="feature-gif" />
                    </div>
                </div>
            </section>
            {/* --- UPDATED SECTION 1 END --- */}


            {/* Feature Section 2 */}
            {/* --- UPDATED SECTION 2 START --- */}
            <section className="feature-section feature-section-codebase">
                <div className="feature-text-area">
                    <h2 className="feature-title">Image & Video to 3D</h2>
                    <p className="feature-description">Instantly transform 2D images or video footage into detailed 3D models and scenes with Neocu's powerful AI conversion engine.</p>
                </div>
                <div className="feature-image-background">
                    <div className="feature-gif-container">
                        <img src={FeatureGif2} alt="Neocu Image and Video to 3D Conversion" className="feature-gif" />
                    </div>
                </div>
            </section>
            {/* --- UPDATED SECTION 2 END --- */}


            {/* 4-Card Section */}
            <section className="card-section card-section-4">
                <h2 className="card-section-title">Key Features</h2>
                <div className="card-grid card-grid-4">
                    {cardData1.map((card, index) => (
                        <div key={index} className="card card-4">
                            <FontAwesomeIcon icon={card.icon} className="card-icon" />
                            <h3 className="card-title">{card.title}</h3>
                            <p className="card-description">{card.description}</p>
                        </div>
                    ))}
                </div>
            </section>

            {/* FAQ Section */}
            <section className="faq-section">
                <h2 className="faq-section-title">Frequently Asked Questions</h2>
                <div className="faq-list">
                    {faqData.map((faq, index) => (
                        <div key={index} className="faq-item">
                            <div className="faq-question" onClick={() => toggleFAQ(index)}>
                                {faq.question}
                                <span className={`dropdown-arrow ${faqActiveIndex === index ? 'active' : ''}`}>▼</span>
                            </div>
                            {faqActiveIndex === index && (
                                <div className="faq-answer">
                                    {faq.answer}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </section>

            {/* 6-Card Section */}
            <section className="card-section card-section-6">
                <h2 className="card-section-title">More Benefits</h2>
                <div className="card-grid card-grid-6">
                    {cardData2.map((card, index) => (
                        <div key={index} className="card card-6">
                            <FontAwesomeIcon icon={card.icon} className="card-icon" />
                            <h3 className="card-title">{card.title}</h3>
                            <p className="card-description">{card.description}</p>
                        </div>
                    ))}
                </div>
            </section>


            <main className="home-main" style={{ display: 'none' }}>
                <p>This is the home page of our dark theme website.</p>
                <p>Navigate to other pages using the links in the header.</p>
            </main>
        </div>
    );
}

export default Home;