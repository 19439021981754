import React from 'react';
import './Features.css'; // Link to Features specific CSS
import HeroImage from '../Hero_1.png'; // Reuse Hero asset
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { // Import A LOT of icons for features
    faMagic, faCube, faLightbulb, faRocket, faQuestionCircle, faCheckCircle, faTools,
    faUsers, faLock, faClock, faCloudUploadAlt, faDesktop, faBrain, faPalette, faBolt,
    faFileImport, faMobileAlt, faCode, faPuzzlePiece, faHistory, faSearch, faLayerGroup,
    faShapes, faObjectGroup, faCamera, faVideo, faProjectDiagram, faSyncAlt, faPaintBrush,
    faExpandArrowsAlt, faHandPointer, faBezierCurve, faNetworkWired, faSlidersH, faSave
} from '@fortawesome/free-solid-svg-icons';
import { faWindows, faApple, faAndroid, faChrome } from '@fortawesome/free-brands-svg-icons'; // Keep for hero potentially

function Features() {

    // Comprehensive list of features
    const featuresData = [
        { icon: faMagic, title: "AI Smart Editing", description: "Context-aware tools that anticipate your next move and suggest relevant actions." },
        { icon: faBrain, title: "Predictive Modeling", description: "AI algorithms assist in generating complex geometry and refining shapes intuitively." },
        { icon: faPalette, title: "AI Material Generation", description: "Create stunning PBR materials from text prompts or image analysis using AI." },
        { icon: faLightbulb, title: "Intelligent Scene Layout", description: "AI suggestions for object placement, lighting, and composition guidance." },
        { icon: faCube, title: "Intuitive 3D Manipulation", description: "User-friendly gizmos and tools for easy modeling, sculpting, and UV unwrapping." },
        { icon: faShapes, title: "Procedural Generation", description: "Generate complex patterns, terrains, and objects using node-based procedural workflows." },
        { icon: faPaintBrush, title: "Advanced Texturing Tools", description: "Paint directly onto models, utilize layer systems, and import/export texture sets." },
        { icon: faBolt, title: "Real-time PBR Rendering", description: "Visualize your creations instantly with a high-fidelity real-time PBR viewport." },
        { icon: faCamera, title: "Cinematic Camera Controls", description: "Set up complex camera shots, animations, and depth-of-field effects easily." },
        { icon: faVideo, title: "Integrated Animation Suite", description: "Keyframe animation, rigging tools, and physics simulation for dynamic scenes." },
        { icon: faUsers, title: "Cloud Collaboration", description: "Work together on projects in real-time with shared assets and version history." },
        { icon: faSyncAlt, title: "Cross-Platform Sync", description: "Seamlessly switch between desktop and mobile devices with cloud project synchronization." },
        { icon: faFileImport, title: "Broad Format Support", description: "Import and export a wide range of 3D file formats (FBX, OBJ, glTF, USD, etc.)." },
        { icon: faLayerGroup, title: "Non-Destructive Workflow", description: "Utilize modifiers and layers for flexible, non-destructive editing." },
        { icon: faCode, title: "Scripting & API Access", description: "Extend Neocu's functionality with Python scripting and access to core APIs." },
        { icon: faPuzzlePiece, title: "Plugin Architecture", description: "Expand capabilities with a growing library of community and official plugins." },
        { icon: faSearch, title: "AI-Powered Asset Search", description: "Quickly find assets within your library or online repositories using natural language." },
        { icon: faHistory, title: "Robust Version Control", description: "Track changes, revert to previous states, and manage project iterations effectively." },
        { icon: faObjectGroup, title: "Scene Organization Tools", description: "Manage complex scenes efficiently with groups, layers, and outliner views." },
        { icon: faHandPointer, title: "Interactive VR/AR Previews", description: "Preview and interact with your creations directly in virtual or augmented reality." },
        { icon: faQuestionCircle, title: "Contextual AI Help", description: "Get instant assistance and tutorials based on your current task from the AI." },
        { icon: faSlidersH, title: "Customizable UI", description: "Tailor the workspace, shortcuts, and themes to fit your preferred workflow." },
        { icon: faLock, title: "Secure Cloud Storage", description: "Your projects are saved securely in the cloud with robust privacy measures." },
        { icon: faSave, title: "Auto-Save & Recovery", description: "Never lose your work with reliable auto-save and project recovery features." }
    ];


    return (
        <div className="features-container"> {/* Use specific class */}

            {/* Hero Section - Features Focus */}
            <section className="hero-section">
                <div className="hero-content-area">
                    <h1 className="hero-title">Purpose Built 3D Editor</h1>
                    <p className="hero-description">Discover the powerful AI and intuitive tools that make Neocu the ultimate 3D editor.</p>
                    <div className="hero-buttons">
                        {/* Optional: Buttons like Get Started or See Pricing */}
                        <a
                            href="/pricing" // Link to pricing
                            className="hero-download-button windows-button" // Reusing style
                        >
                            SEE PRICING
                        </a>
                        <a
                            href="https://editor.neocu.com" // Link to editor
                            className="hero-download-button all-downloads-button" // Reusing style
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            TRY THE EDITOR
                        </a>
                    </div>
                </div>
                <div className="hero-image-container">
                    {/* Maybe a different hero image/video for features? For now, reuse. */}
                    <img src={HeroImage} alt="Features Hero" className="hero-image" />
                </div>
            </section>

            {/* Features Grid Section */}
            <section className="features-list-section">
                <h2 className="features-list-title">Comprehensive Feature Set</h2>
                <div className="features-grid">
                    {featuresData.map((feature, index) => (
                        <div key={index} className="feature-card">
                            <FontAwesomeIcon icon={feature.icon} className="feature-card-icon" />
                            <h3 className="feature-card-title">{feature.title}</h3>
                            <p className="feature-card-description">{feature.description}</p>
                        </div>
                    ))}
                </div>
            </section>

        </div>
    );
}

export default Features;