import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../logo.png';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faWindows,
    faApple,
    faAndroid,
    faChrome
} from '@fortawesome/free-brands-svg-icons';
import {
    faUserCircle,
    faXmark,
    faBars // Import hamburger icon
} from '@fortawesome/free-solid-svg-icons';

import { SignInButton, SignOutButton, useUser, UserProfile } from "@clerk/clerk-react";

function Header() {
    const [device, setDevice] = useState('default');
    const { isSignedIn, user } = useUser();
    const [showUserDropdown, setShowUserDropdown] = useState(false);
    const [isUserProfileModalOpen, setIsUserProfileModalOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // State for mobile menu
    const dropdownRef = useRef(null); // Ref for dropdown click outside

    // Detect OS
    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.includes('windows')) setDevice('windows');
        else if (userAgent.includes('mac') || userAgent.includes('darwin')) setDevice('mac');
        else if (userAgent.includes('ios') || userAgent.includes('iphone') || userAgent.includes('ipad')) setDevice('ios');
        else if (userAgent.includes('android')) setDevice('android');
        else if (userAgent.includes('chrome') && userAgent.includes('cros')) setDevice('chromebook');
        else setDevice('default');
    }, []);

    // Close dropdown on click outside
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowUserDropdown(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    const getDownloadIcon = () => {
        switch (device) {
            case 'windows': return <FontAwesomeIcon icon={faWindows} />;
            case 'mac': return <FontAwesomeIcon icon={faApple} />;
            case 'ios': return <FontAwesomeIcon icon={faApple} />;
            case 'android': return <FontAwesomeIcon icon={faAndroid} />;
            case 'chromebook':
            default: return <FontAwesomeIcon icon={faChrome} />;
        }
    };

    const toggleUserDropdown = () => {
        setShowUserDropdown(prev => !prev);
    };

    const closeUserDropdown = () => {
        setShowUserDropdown(false);
    };

    const handleProfileClick = () => {
        console.log('Profile clicked'); // KEEP CONSOLE LOG
        setIsUserProfileModalOpen(true);
        closeUserDropdown();
        setIsMobileMenuOpen(false); // Close mobile menu if open
    };

    const closeUserProfileModal = () => {
        setIsUserProfileModalOpen(false);
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(prev => !prev);
    };

    const closeMobileMenu = () => {
        setIsMobileMenuOpen(false);
    }

    // Shared navigation links render function
    const renderNavLinks = (isMobile = false) => (
        <>
            <li><Link to="/pricing" onClick={isMobile ? closeMobileMenu : undefined}>PRICING</Link></li>
            <li><Link to="/features" onClick={isMobile ? closeMobileMenu : undefined}>FEATURES</Link></li>
            <li><Link to="/enterprise" onClick={isMobile ? closeMobileMenu : undefined}>ENTERPRISE</Link></li>
            <li><Link to="/careers" onClick={isMobile ? closeMobileMenu : undefined}>CAREERS</Link></li>
        </>
    );

    // Shared actions render function
    const renderActions = (isMobile = false) => (
        <>
            {!isSignedIn && (
                <SignInButton mode="modal" asChild afterSignInUrl="/">
                    <button className="signin-button-custom" onClick={isMobile ? closeMobileMenu : undefined}>
                        SIGN IN
                    </button>
                </SignInButton>
            )}

            {isSignedIn && (
                <div className={`profile-container-header ${isMobile ? 'mobile-profile-section' : ''}`} ref={!isMobile ? dropdownRef : null}>
                    {isMobile ? (
                        <>
                             {/* Simplified profile display for mobile menu */}
                             <div className="mobile-user-info">
                                {user?.imageUrl ? (
                                    <img src={user.imageUrl} alt="Profile" className="mobile-profile-pic" />
                                ) : (
                                    <span className="profile-initials-header">
                                        {user?.firstName ? user.firstName.charAt(0).toUpperCase() : 'U'}
                                    </span>
                                )}
                                <span>{user?.primaryEmailAddress?.emailAddress || 'User'}</span>
                            </div>
                            <div className="mobile-menu-item" onClick={() => {
                                handleProfileClick(); // Will also close mobile menu
                            }}>
                                Profile
                            </div>
                            <div className="mobile-menu-item mobile-signout-item">
                                <SignOutButton>
                                    <button onClick={closeMobileMenu}>Sign Out</button>
                                </SignOutButton>
                            </div>
                        </>
                    ) : (
                        <>
                             {/* Desktop profile button and dropdown */}
                            <button className="profile-button-header" onClick={toggleUserDropdown}>
                                {user?.imageUrl ? (
                                     <img src={user.imageUrl} alt="Profile" className="desktop-profile-pic" />
                                ) : user?.firstName ? (
                                    <span className="profile-initials-header">
                                        {user.firstName.charAt(0).toUpperCase()}
                                    </span>
                                ) : (
                                    <FontAwesomeIcon icon={faUserCircle} style={{ color: '#90D6FF', fontSize: '1.7em'}} />
                                )}
                            </button>

                            {showUserDropdown && (
                                <div className="user-dropdown-header">
                                    <div className="dropdown-item" onClick={() => {
                                        console.log("Inline Profile Click Handler"); // NEW INLINE CONSOLE LOG
                                        handleProfileClick();
                                    }}>
                                        Profile
                                    </div>
                                    <div className="dropdown-item signout-dropdown-item">
                                        <SignOutButton>
                                            Sign Out
                                        </SignOutButton>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}

            <a
                href="https://editor.neocu.com"
                className="download-button"
                target="_blank"
                rel="noopener noreferrer"
                onClick={isMobile ? closeMobileMenu : undefined}
            >
                {getDownloadIcon()}
                DOWNLOAD
            </a>
        </>
    );


    return (
        <>
            <header className="app-header">
                <div className="header-left">
                    <Link to="/" className="logo-link">
                        <img src={logo} className="app-logo" alt="logo" />
                    </Link>
                    <span className="neocu-text"><b>NEOCU</b></span>
                </div>

                {/* Desktop Navigation */}
                <nav className="main-nav desktop-only">
                    <ul>
                        {renderNavLinks()}
                    </ul>
                </nav>

                {/* Desktop Actions */}
                <div className="header-right desktop-only">
                    {renderActions()}
                </div>

                {/* Hamburger Menu Button - Mobile Only */}
                <div className="header-right mobile-only">
                     <button className="hamburger-menu" onClick={toggleMobileMenu} aria-label="Toggle menu">
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                </div>
            </header>

            {/* Mobile Menu Overlay */}
            {isMobileMenuOpen && (
                <div className="mobile-menu-overlay" onClick={closeMobileMenu}>
                    <div className="mobile-menu-content" onClick={(e) => e.stopPropagation()}>
                         <button className="mobile-menu-close" onClick={closeMobileMenu} aria-label="Close menu">
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                        <nav className="mobile-nav">
                            <ul>
                                {renderNavLinks(true)}
                            </ul>
                        </nav>
                        <div className="mobile-actions">
                            {renderActions(true)}
                        </div>
                    </div>
                </div>
            )}


            {/* User Profile Modal */}
            {isUserProfileModalOpen && (
                <UserProfileModal onClose={closeUserProfileModal} />
            )}
        </>
    );
}

// User Profile Modal Component (Unchanged)
const UserProfileModal = ({ onClose }) => {
    return (
        <div className="compute-modal-overlay" onClick={onClose} style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
            <div className="compute-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="modal-close-button" onClick={onClose}>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
                 {/* Added padding inside modal content for Clerk component */}
                <div style={{ padding: '20px', width: '100%' }}>
                     <UserProfile path="/user-profile" routing="path" /> {/* Use path routing */}
                </div>
            </div>
        </div>
    );
};

export default Header;