import React from 'react';
import './Careers.css'; // Link to Careers specific CSS

function Careers() {
    return (
        <div className="careers-container">
            {/* The .careers-content div now acts as the card */}
            <div className="careers-content">
                <h1 className="careers-title">Join the Neocu Team</h1>
                <p className="careers-description">
                    We're building the future of 3D creation and looking for passionate individuals
                    to help us redefine productivity. If you're excited about AI, 3D technology,
                    and creating amazing tools, we'd love to hear from you.
                </p>
                <p className="careers-contact">
                    Interested? Send your resume and cover letter to: <br />
                    <a href="mailto:career@neocu.com" className="careers-email-link">career@neocu.com</a>
                </p>
            </div>

            {/* Optional: Could add sections for open positions later */}
            {/* Make sure to uncomment the CSS for .open-positions if you uncomment this */}
            {/*
            <div className="open-positions">
                <h2>Open Positions</h2>
                <p>Currently, we are actively seeking candidates for...</p>
                <ul>
                    <li>Senior React Developer</li>
                    <li>AI/ML Engineer (Computer Vision)</li>
                    <li>3D Graphics Engineer (C++)</li>
                </ul>
                 <p>Even if you don't see a perfect fit, feel free to reach out!</p>
            </div>
            */}
        </div>
    );
}

export default Careers;